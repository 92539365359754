import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { Button } from "components/UI";
import { useChurchStore } from "features/churches";
import {
  employeeSchema,
  useCreateEmployee,
  useEmployeesStore
} from "features/hr";
import { MemberDataManipulator } from "features/members";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsXSquare } from "react-icons/bs";
import { EmployeeModalSteps } from "../EmployeeModalSteps";
import { AddressForm } from "../Forms/AddressForm";
import { BankForm } from "../Forms/BankForm";
import { CompensationForm } from "../Forms/CompensationForm";
import { EmergencyContactForm } from "../Forms/EmergencyContactForm";
import { JobForm } from "../Forms/JobForm";
import { PersonalInfoForm } from "../Forms/PersonalInfoForm";

const CloseIcon = styled(BsXSquare)`
  color: #ffffff;
  height: 22px;
  width: 22px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
`;

export const AddEmployeeModal = ({ open, onClose }) => {
  const addEmployeeFormActiveStep = useEmployeesStore(
    state => state.addEmployeeFormActiveStep
  );
  const setAddEmployeeFormActiveStep = useEmployeesStore(
    state => state.setAddEmployeeFormActiveStep
  );
  const selectedChurch = useChurchStore(state => state.selectedChurch);

  const { t } = useTranslation();

  const createEmployeeMutation = useCreateEmployee();

  const {
    register,
    control,
    formState: { errors, defaultValues },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      employee_id: "",
      title: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      date_of_birth: "",
      nationality_id: "66",
      marital_status: "",
      employee_photo: null,
      phone_number: "",
      email: "",
      address: "",
      position: "",
      employment_date: new Date(),
      employment_type: "",
      salary_bank_name: "",
      salary_bank_account_number: "",
      pf_bank_name: "",
      pf_bank_account_number: "",
      social_id_number: "",
      tin_number: "",
      gross_salary: "",
      overtime: "",
      transport_allowance: "",
      house_allowance: "",
      communication_allowance: "",
      bonus: "",
      tax: "",
      credit: "",
      pension: "",
      provident_fund: "",
      penalty: "",
      emergency_contact_title: "",
      emergency_contact_first_name: "",
      emergency_contact_last_name: "",
      emergency_contact_middle_name: "",
      relationship_to_employee: "",
      emergency_contact_phone: "",
      emergency_contact_address: ""
    },
    mode: "onBlur",
    resolver: yupResolver(employeeSchema)
  });

  const forms = {
    1: (
      <PersonalInfoForm
        register={register}
        errors={errors}
        control={control}
        setValue={setValue}
      />
    ),
    2: <AddressForm register={register} errors={errors} control={control} />,
    3: <JobForm register={register} errors={errors} />,
    4: <BankForm register={register} errors={errors} />,
    5: <CompensationForm register={register} errors={errors} />,
    6: (
      <EmergencyContactForm
        register={register}
        errors={errors}
        control={control}
      />
    )
  };

  const onSubmit = values => {
    const cleanEmployeeData = {
      ...values
    };

    const employeeDataManipulator = new MemberDataManipulator();

    employeeDataManipulator.removeEmptyValues(cleanEmployeeData);

    employeeDataManipulator.removeEmptyValues(cleanEmployeeData);

    employeeDataManipulator.convertValuesToDates(cleanEmployeeData, [
      "date_of_birth",
      "employment_date"
    ]);

    const employeeFormData =
      employeeDataManipulator.convertObjectToFormData(cleanEmployeeData);

    if (values.employee_photo) {
      employeeFormData.append("employee_photo", values.employee_photo);
    }

    createEmployeeMutation.mutate(
      {
        churchId: selectedChurch?.id,
        employeeData: employeeFormData
      },
      {
        onSuccess: () => {
          reset({
            ...defaultValues
          });
        }
      }
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setAddEmployeeFormActiveStep(1);
        onClose();
      }}
    >
      <DialogTitle sx={{ bgcolor: "primary.main" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" color="primary.contrastText">
            Employee Registration
          </Typography>
          <CloseIcon onClick={onClose} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack py={1} spacing={2}>
          <Typography variant="body2">
            {t("HR.AddEmployee.Fill EmployeeDetail")}
          </Typography>
          <EmployeeModalSteps />
          {forms[addEmployeeFormActiveStep]}
        </Stack>
        {createEmployeeMutation.isError && (
          <Alert variant="filled" severity="error">
            {typeof createEmployeeMutation.error?.errors === "string" ? (
              <div>{createEmployeeMutation.error?.errors}</div>
            ) : (
              Object.values(createEmployeeMutation.error?.errors)?.map(err => (
                <div key={err}>{err}</div>
              ))
            )}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button flavor="text" onClick={onClose}>
          {t("form.common.cancel")}
        </Button>
        {addEmployeeFormActiveStep !== 1 && (
          <Button
            flavor="outlined"
            onClick={() =>
              setAddEmployeeFormActiveStep(addEmployeeFormActiveStep - 1)
            }
          >
            {t("form.common.back")}
          </Button>
        )}
        {addEmployeeFormActiveStep !== 6 && (
          <Button
            onClick={() =>
              setAddEmployeeFormActiveStep(addEmployeeFormActiveStep + 1)
            }
          >
            {t("form.common.next")}
          </Button>
        )}
        {addEmployeeFormActiveStep === 6 && (
          <Button onClick={handleSubmit(onSubmit)}>
            {t("form.common.submit")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
